import React from "react"
import {graphql, StaticQuery} from "gatsby"
import {List, Item, Header, Picture, Info, Title, Para} from "../styles/inventoryStyles"
import {getImage} from "../helpers";

const Inventory = () => (
  <>
    <StaticQuery
      query={graphql`
          query InventoryQuery {
            allTaxonomyTermCategory(sort: {name: ASC}) {
              edges {
                node {
                  name
                  path {
                    alias
                  }
                  relationships {
                    field_image {
                    field_media_image {
                      title
                      alt
                    }
                    relationships {
                      field_media_image {
                         url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <List>
          {data.allTaxonomyTermCategory.edges.map(({node}, i) => {
            let image = getImage(node);
            return (
              <Item key={i} to={`${node.path.alias}/`}>
                <Picture alt={image.alt} title={image.title} src={image.url} />
                <Header>{node.name}</Header>
              </Item>
            );
          })}
          <Info>
            <Title>Explore Our Diverse Inventory of Used Heavy Equipment</Title>
            <Para>At SR Affordable Equipment, LLC, we take pride in offering a wide range of high-quality used heavy equipment to cater to the diverse needs of our clients. Our inventory is constantly updated with meticulously inspected and well-maintained machinery to ensure that you receive the best value for your investment.</Para>
            <Title>Find the Perfect Match for Your Project</Title>
            <Para>Our expansive inventory covers various types of machinery, including excavators, skid steers, tractors, and more, from industry-leading brands like Caterpillar, Komatsu, and John Deere. With options suitable for construction, agriculture, and forestry applications, you're sure to find the perfect piece of equipment to meet your project requirements.</Para>
            <Para>Our user-friendly website allows you to browse through our inventory with ease, filtering by categories, makes, models, and price ranges, to help you identify the ideal machine for your needs. Each listing provides detailed information, including specifications, photos, and a comprehensive description, giving you a clear understanding of the equipment's condition and capabilities.</Para>
            <Title>Unmatched Customer Support</Title>
            <Para>At SR Affordable Equipment, LLC, we believe in going the extra mile for our clients. Our experienced team is always available to address any questions or concerns you may have about a particular piece of equipment, and provide expert advice on selecting the right machine for your specific requirements.</Para>
            <Para>We understand that purchasing used heavy equipment is a significant investment, and we're committed to making the process as smooth and hassle-free as possible. Trust SR Affordable Equipment, LLC to be your reliable partner in acquiring the best-used construction equipment to help your business succeed.</Para>
            <Para>Explore the latest machinery available and discover the ideal equipment solution for your next project.</Para>
          </Info>
        </List>
      )}
    />

  </>
)

export default Inventory